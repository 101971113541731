.nav-logo{
     width: 180px;
     height: 40px;
 }
 .cart-btn{
     padding: 5px 15px 5px 15px;
     color: #FFFFFF;
     border-radius: 20px !important;
     border:0.5px solid #051b35;
     background: #051b35 !important;
     text-decoration: none;
 }
 .navbar { 
     font-size: 16px;
     font-family: 'Roboto Condensed', sans-serif;
     font-weight: 400;
     background-color: #ffffff !important;
     box-shadow: 0 0 8px 0 rgba(57, 78, 234, 0.1)
 }

 .TopSectionDown{
      margin-bottom: 80px;
 }