.accordion {
     background-color: #f8f8f8;
     color: #444;
     cursor: pointer;
     padding: 7px;
     width: 100%;
     border: 1px solid #ddd;
     font-size: 14px;
     text-align: left;
     outline: none;
     font-weight: 600;
     font-family: 'Roboto Condensed', sans-serif;
     transition: 0.4s;
     overflow: paged-y;
     box-shadow: 0 0 2px 0 rgba(57, 78, 234, 0.1)
 }
 .active, .accordion:hover {
     color: #ffffff;
     font-weight: 400;
     background-color: #051b35;
 }
 .accordion:after {
     content: '\276F';
     color: #c2c2c2;
     float: right;
     margin-left: 5px;
 }
 .active:after,hover {
     color: #ffffff;
     content: "\276E";
 }
 .panel {
     padding: 0 10px;
     background-color: white;
     max-height: 0;
     overflow: hidden;
     transition: max-height 0.2s ease-out;
 }
 .accordionMenuDiv{
     width: 100%;
     direction:rtl;
     font-family: 'Roboto Condensed', sans-serif !important;
     font-weight: 300 !important;
     height: 380px;
     overflow-y: auto;
     overflow-x: hidden;
     scrollbar-width: thin;
     scrollbar-color: #e9e9e9 #ffffff;
 }
 
 .accordionMenuDiv::-webkit-scrollbar {
     width:10px !important;
 }
 
 .accordionMenuDiv::-webkit-scrollbar-thumb {
     background: #dedede !important;
 }
 
 .accordionMenuDiv::-webkit-scrollbar-thumb:hover {
     background: #808080 !important;
 }
 
 .accordionMenuDiv::-webkit-scrollbar-track {
     background: #F4F4F4!important;
 }
 
 
 .accordionMenuDivInside{
     direction:ltr;
 }
 .accordionMenuIcon{
     width: 20px;
     height: auto;
 }
 .accordionItem{
     color: #444;
     text-decoration: none;
 }
 .accordionItem:hover{
     text-decoration: none;
 }